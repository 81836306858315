/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // slick basic
        $('.slider').slick({
          autoplay: true,
          dots: true,
          autoplaySpeed: 99999
        });


        // stop video playback on modal close
        // http://stackoverflow.com/a/25069916
        $(".video-modal").on('hidden.bs.modal', function (e) {
          var id = $(this).attr('id');
          var target = "#" + id + ".video-modal iframe";

          $(target).attr("src", $(target).attr("src"));
        });

	      /**********************************************
	       * ********************************************
	       * ******* Equal heights
	       * ********************************************
	       */
	      var screenWidth = $( window ).width();
	      ///// Equalize height in rows
	      var equalheight = function (container, minsize) {
		      var currentTallest = 0,
				      currentRowStart = 0,
				      rowDivs = [],
				      $el,
				      $currentDiv,
				      topPosition = 0;
		      $(container).each(function () {
			      screenWidth = $( window ).width();
			      var classes = $(container).attr('class').split(' ');
			      for(var i = 0; i < classes.length; i++) {
				      //console.log(classes[i]);
			      }

			      $el = $(this);
			      $($el).height('auto');

			      if(screenWidth > minsize){
				      topPostion = $el.position().top;
				      if (currentRowStart !== topPostion) {
					      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
						      rowDivs[currentDiv].height(currentTallest);
					      }
					      rowDivs.length = 0; // empty the array
					      currentRowStart = topPostion;
					      currentTallest = $el.height();
					      rowDivs.push($el);
				      } else {
					      rowDivs.push($el);
					      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				      }
				      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
			      }
		      });
	      };
	      ////// end equalheight();
        function wrappedResize(parentClasses, elemClass) {
          var capHeight = 0;
          $(elemClass).css({height: 'auto'});

          if(!$('.navbar-toggle').is(':visible')){

            $(parentClasses).each(function(e){
              capHeight = 0;
              $(elemClass,$(this)).each(function(e){
                if($(this).outerHeight() > capHeight) {
                  capHeight = $(this).outerHeight();
                }
              });
              $(elemClass,$(this)).css({height: capHeight});
            });
          }
        }
	      ////////////////////////////////
	      ////////////////////////////////

	      //// general equalize heights call after load fallback
	      // function equalizeHeights(){
		     //  equalheight('.card', 767);
	      // }
	      // setTimeout(function(){ equalizeHeights(); }, 1100);

        function resizeFuntions() {
          wrappedResize('.home-section, .blog, .card-repeater','.card .thumbnail');
          wrappedResize('.repeater-tabs','.tablinks');
	        wrappedResize('.equal-these','.equals');
        }

	      $('.dropdown').mouseover(function(e) {
		      $(this).addClass('open');
	      });
	      $('.dropdown').mouseout(function(e) {
		      $(this).removeClass('open');
	      });

        $('.dropdown>.caret-wrapper').click(function(e) {
          e.stopPropagation();
          $(this).parent().toggleClass('mobile-open');
          $(this).toggleClass('flipped');
        });

        $('.no-link > a').click(function(e) {
          e.preventDefault();
        });

        setTimeout(function(){
          resizeFuntions();
        }, 1100);

        $(window).resize(function() {
          resizeFuntions();
        });

	      /**********************************************
	       * ********************************************
	       * ******* Basic Tabs
	       * ********************************************
	       */
	      ///// modified to handle multiple tabgroups on a single page
	      function openTab(evt, tabName, tabGroup) {
		      // variables
		      var i, tabcontent, tablinks;

		      tabGroup = '#' + tabGroup;
		      jQuery(tabGroup).find('.tabcontent').removeClass('active');
		      jQuery(tabGroup).find('.tablinks').removeClass('active');

		      // Show the current tab, and add an "active" class to the button that opened the tab
		      var compTab = tabGroup + ' #' + tabName + '-Tab';
		      jQuery(compTab).addClass('active');
		      var compTabContent = tabGroup + ' #' + tabName;
		      jQuery(compTabContent).addClass('active');
	      }

	      $('.tablinks').click(function() {
		      var val = $(this).attr('id');
		      var par = $(this).parent().parent().parent().attr('id'); //// pass the parent to set scope
		      openTab(event, val, par);
	      });

	      ////// end openTab() and calls;
	      //////////////////////////////////
	      //////////////////////////////////

	      ////////////////////////////
	      //////// begin Dynamic Outbound Link Logging
	      function getRootUrl() {
		      return window.location.origin?window.location.origin+'/':window.location.protocol+'/'+window.location.host+'/';
	      }

	      $('a').click(function(e){
		      //e.preventDefault(); - only for testing
		      //// we want to dynamically record outbound link values for GA
		      var baseCompare = getRootUrl(); //// get the root of the domain
		      var URLValue = $(this).attr("href"); //// get the value of the hyperlink address
		      var coverRelative = URLValue.indexOf('http'); //// it's possible for a relative link to sneak in - so if there's no http we know it's not outbound
		      var localCheck = false; //// set a variable to work through our checks
		      var isDomain = URLValue.indexOf(baseCompare); //// firstCheck and set value
		      if( isDomain === -1 ){
			      localCheck = false;
		      }else{
			      localCheck = true;
		      }
		      //// now we can make sure it's not just a relative link that tricked us
		      if( coverRelative === -1 ){
			      localCheck = true;
		      }
		      var endExternal = '';
		      if( !localCheck ){
			      var startExternal = URLValue.indexOf('//');
			      endExternal = URLValue.substr( startExternal+2 , URLValue.length );
			      console.log( 'endExternal = ' + endExternal );
			      var containsWWW = endExternal.indexOf('www');
			      if( containsWWW !== -1 ){ //// if www is present trim to just the domain and suffix
				      endExternal = endExternal.substr( 4 );
			      }
		      }
		      if( !localCheck ){
			      ga('create', gaCode, 'auto');
			      var pushCategoryToGoogle = 'Outbound Click';
			      var pushActionToGoogle = endExternal;
			      var pushLabelToGoogle = URLValue;
			      ga('send', 'event', pushCategoryToGoogle, pushActionToGoogle, pushLabelToGoogle);
		      }
	      });
	      /////// end Dynamic Outbound Link Logging
	      /////////////////////

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
